import { Flex, Image, useTheme, View } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

const Text: FunctionComponent = () => {
  const { tokens } = useTheme();
  return (
    <View
      color={tokens.colors.overlay[70]}
      fontSize={124}
      fontWeight={tokens.fontWeights.semibold}
      lineHeight={1}
    >
      4
    </View>
  );
};

const NotFoundPage: FunctionComponent = () => {
  const { tokens } = useTheme();
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      marginTop={tokens.space.xxxl}
    >
      <Flex alignItems="center" justifyContent="center">
        <Text />
        <Image
          src="/logo.svg"
          alt="Hone"
          marginTop={6}
          height={96}
          width={96}
        />
        <Text />
      </Flex>
      <View
        fontSize={tokens.fontSizes.xxl}
        marginTop={tokens.space.medium}
        marginRight={tokens.space.small}
        marginLeft={tokens.space.small}
        textAlign="center"
      >
        Page Not Found
      </View>
    </Flex>
  );
};

export default NotFoundPage;
