import '@aws-amplify/ui-react/styles.css';
import '@/styles.css';

import { Authenticator } from '@aws-amplify/ui-react';
import { GatsbyBrowser } from 'gatsby';
import Helmet from 'react-helmet';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  const faviconUrl = '/favicon.ico';
  return (
    <>
      <Helmet
        title="Hone"
        link={[
          { rel: 'icon', type: 'image/x-icon', href: faviconUrl },
          {
            rel: 'shortcut icon',
            type: 'image/x-icon',
            href: faviconUrl,
          },
        ]}
      />
      <Authenticator.Provider>{element}</Authenticator.Provider>
    </>
  );
};
